import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers';
import { Component, BaseComponent, Prop } from '@zento-lib/components';

import { MainImage } from '../../MainImage/MainImage';
import { Button } from '../../Button/Button';
import type { CategoryTypes } from '../../types/CategoryTypes';

import type { IMenuLinkPromoted } from './Promoted.d';
import style from './style.scss';

/**
 * Menu Link Promoted
 *
 * Main menu promoted link - highlights a specific category
 **/
@Component({})
export class MenuLinkPromoted extends BaseComponent<IMenuLinkPromoted, unknown> {
  /**
   * Denotes the target route of the link. When clicked, the value of the to prop will be passed to
   * router.push() internally, so the value can be either a string or a location descriptor object.
   */
  @Prop({ type: Object, required: true })
  item: CategoryTypes;

  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   */
  @Prop({ type: Function, default: () => undefined })
  handler?: (ev: Event) => void;

  get location(): any {
    return formatCategoryLink({ url_path: this.item.url_path, slug: this.item.slug });
  }

  onClick(ev: MouseEvent) {
    if (this.handler) {
      // Execute handler
      this.handler(ev);
    }

    if (!ev.defaultPrevented) {
      ev.preventDefault();
      this.$router.push(this.location, this.noOp);
    }
  }

  private noOp() {
    // Do nothing
  }

  beforeMount() {
    this.onClick = this.onClick.bind(this);
  }

  get imageSizes() {
    return this.extended.$config.zento.images.menuPromoted;
  }

  render() {
    const config = this.extended.$config.zento.promotedMenuItem;
    const image = {
      src: this.item.promoted_category_image
        ? this.item.promoted_category_image
        : this.item.promoted_image
        ? this.item.promoted_image
        : this.item.image
        ? this.item.image
        : '',
      loading: this.item.promoted_category_image
        ? this.item.promoted_category_image
        : this.item.promoted_image
        ? this.item.promoted_image
        : this.item.image
        ? this.item.image
        : '',
    };

    return (
      <div>
        <MainImage
          image={image}
          width={this.imageSizes.width}
          height={this.imageSizes.height}
          tabletWidth={this.imageSizes.width}
          tabletHeight={this.imageSizes.height}
          desktopWidth={this.imageSizes.width}
          desktopHeight={this.imageSizes.height}
          alt={this.item.name}
          folder=''
          resize='xc'
          key='promoted-menu-link-img'>
          {!config.linkAfterImage ? (
            <Button styleType={config.buttonStyle} handler={this.onClick} slot='after' class={style.promotedLink}>
              {this.item.name}
            </Button>
          ) : null}
        </MainImage>

        {config.linkAfterImage ? (
          <Button styleType={config.buttonStyle} handler={this.onClick} class={style.promotedLink}>
            {this.item.name}
          </Button>
        ) : null}
      </div>
    );
  }
}
